<template>
    <div
        v-if="modalShow"
        class="onb-wrap"
    >
        <div class="onb-overflow" />
        <template v-for="(node, index) in currentStep.selectedElements">
            <div
                :key="index"
                class="selected-area"
                :style="makeSelectedAreaStyles(node.offset)"
            >
                <div v-html="node.element.outerHTML" />
            </div>
        </template>
        <div
            class="onb-modal"
            :style="makeModalStyles"
        >
            <div
                class="onb-title"
                v-html="currentStep.message.title"
            />
            <div class="onb-content">
                <span
                    class="subtitle"
                    v-html="currentStep.message.subtitle"
                /> <span
                    class="text"
                    v-html="currentStep.message.text"
                />
                <div
                    v-if="currentStep.message.video && currentStep.message.video.length > 0"
                    class="video"
                >
                    <iframe
                        :src="currentStep.message.video"
                        frameborder="0"
                        width="400px"
                        height="225px"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                </div>
            </div>
            <div class="onb-footer">
                <button
                    v-for="(button, index) in currentStep.message.buttons"
                    :key="index"
                    type="button"
                    :class="[button.buttonClassNames, {'ms-8': index > 0}]"
                    @click.prevent="buttonAction(button.buttonAction)"
                    v-html="button.buttonText"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'XOnboardingStepper',
    methods: {
        makeSelectedAreaStyles(offset) {
            let left = `left:${offset.left}px;`;
            let top = `top:${offset.top}px;`;
            let width = `width:${offset.width}px;`;
            return left + top + width;
        },
        buttonAction(action) {
            if (typeof action === 'function') {
                action();
            }
            if (typeof this.currentStep.callback === 'function') {
                this.currentStep.callback();
            }
        },
    },
    computed: {
        makeModalStyles() {
            let {
                left, top, width, height,
            } = this.currentStep.message.offset;
            let align = this.currentStep.message.offset.align ?? false;
            let maxWidth = window.innerWidth - left - 24;
            let leftS = `left:${left}px;`;
            let topS = `top:${top}px;`;
            if (align) {
                topS = 'top: 50vh;';
                leftS = 'left: 50%; transform: translate(-50%,-50%);';
            }
            let widthS = `width:${width}px;`;
            let maxWidthS = `max-width:${maxWidth}px;`;
            let minHeightS = `min-height:${height}px;`;
            return leftS + topS + widthS + minHeightS + maxWidthS;
        },
        ...mapGetters('on_boarding', [
            'modalShow', 'currentStep',
        ]),
    },
};
</script>
<style lang="scss">
    .onb-wrap {
        .onb-overflow {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 70;
        }
    }

    .onb-overflow {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(27, 33, 36, 0.2), rgba(27, 33, 36, 0.2));
        z-index: 70;
    }

    .selected-area,
    .onb-modal {
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        z-index: 80;
    }

    .selected-area {
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        z-index: 80;
        border-radius: 4px;
        border: 1px solid #D7E5FD;
        box-sizing: border-box;
        box-shadow: 0 0 0 4px #D7E5FD;
        overflow: hidden;
        padding: 8px;

        > * {
            width: 100%;
        }

        .row {
            margin-top: 12px;
            margin-bottom: 12px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .me-16 {
            margin-right: 0 !important
        }

        .mb-8 {
            margin-bottom: 0 !important;
        }

        .x-tabs-bar .x-tab {
            padding-bottom: 0;
        }

        .overview-block-info {
            position: static;
        }

        .avatar-admin {
            margin-right: 8px;
        }

        .avatar-admin img {
            max-width: 100%;
            max-height: 100%;
            width: 24px;
            height: 24px;
            min-width: 24px;
        }

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 90;
        }
    }

    .onb-modal {
        padding: 16px 24px 24px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .onb-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1B2124;
            margin-bottom: 8px;

            span {
                font-weight: normal;
            }
        }

        .onb-content {
            margin-bottom: 24px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;

            .subtitle {
                font-weight: 600;
                text-transform: uppercase;
            }

            .video {
                margin-top: 16px;
            }

            a {
                font-weight: 600
            }

            .styled-link {
                cursor: pointer;
                font-weight: 600;
                color: #357bf2;

                &:hover {
                    color: #2a62c2;
                }
            }
        }

        .onb-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
</style>
