<template>
    <div class="col-md-12">
        <div :class="{'input-group' : additionalBlock }">
            <div class="input-wrap">
                <select
                    ref="input"
                    v-bind="attributes"
                />
            </div>

            <x-relation-info
                v-if="additionalBlock"
                :module="module"
                :category="category"
                :name="name"
                :value="valueForAdditionalBlock"
                @input="$emit('input', $event)"
            />
        </div>
    </div>
</template>

<script>
import XSelect from '@/components/common/inputs/XSelect';
import XRelationInfo from '@/components/common/inputs/XRelationInfo/XRelationInfo';

export default {
    name: 'XSelectRelation',
    components: { XRelationInfo },
    extends: XSelect,
    inheritAttrs: false,
    props: {
        valueForAdditionalBlock: [String, Number, Array, Object],
        additionalBlock: {
            type: Boolean,
            default: false,
        },
        module: String,
        category: String,
    },
};
</script>
