import {
    SET_PAYMENT_VIEW_DATA,
} from '@/apps/portal/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { isset, toCamelCase } from '@/utils/functions';

const state = {
    model: {},
    invoice: {},
    request: {},
    creditNote: {},
    paymentsTypesArray: {},
    attributesMeta: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalData({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/portal/finance/documents--view?type=payment&id=${id}`,
                method: 'GET',
                success: (response) => {
                    if (isset(response, 'result') && response.result === false) {
                        showErrors(response.message, 4);
                        reject();
                        return;
                    }
                    commit(SET_PAYMENT_VIEW_DATA, response);
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },
};

const mutations = {
    [SET_PAYMENT_VIEW_DATA](state, info) {
        for (let key in info) {
            state[toCamelCase(key)] = info[key];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
