<template>
    <div class="input-holder-checkbox">
        <div
            ref="formSwitch"
            :class="formSwitchClass"
            data-test-selector="form-switch"
        >
            <input
                v-bind="options"
                :id="id"
                v-model="changed"
                :data-selector-value="dataTestValue"
                type="checkbox"
                class="form-check-input"
                @change="inputHandler"
            >
            <label
                v-if="!isFormSwitch && !empty(label)"
                data-test-selector="label-checkbox"
                v-html="label"
            />
        </div>
        <label
            v-if="booleanLabel"
            data-test-selector="label-boolean"
            :class="booleanLabelClass"
        >
            {{ booleanLabelText }}
        </label>
        <label
            v-else-if="labelClass"
            data-test-selector="label-badge"
            :class="`badge ${labelClass}`"
        >{{ label.text || '' }}</label>
        <label
            v-else-if="!empty(label) && isFormSwitch"
            data-test-selector="label-simple"
            v-html="label"
        />
    </div>
</template>

<script>
import { empty } from '@/utils/functions';
import { COMMON_BADGE_COLORS } from '@/constants';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputCheckbox',
    mixins: [SplInputMixin],
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        // For v-model
        value: {
            default: false,
            type: [Boolean, String, Number],
        },
        name: {
            default: 'name',
            type: String,
        },
        id: {
            default() {
                return this.name;
            },
            type: String,
        },
        label: {
            type: [Object, String],
            default: '',
        },
        isFormSwitch: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            changed: !!JSON.parse(this.value),
            labelTypes: COMMON_BADGE_COLORS,
        };
    },
    computed: {
        dataTestValue() {
            return this.changed ? 'on' : 'off';
        },
        booleanLabel() {
            return this.label?.active && this.label?.disabled;
        },
        booleanLabelClass() {
            return ['badge', this.value ? this.labelTypes.success : this.labelTypes.danger];
        },
        booleanLabelText() {
            return this.value ? this.label.active : this.label.disabled;
        },
        labelClass() {
            if (typeof this.label === 'object') {
                const color = Object.keys(this.labelTypes)
                    .find((type) => type === this.label.type);
                return this.labelTypes[color];
            }
            return '';
        },
        formSwitchClass() {
            return `form-check ${this.isFormSwitch ? 'form-switch' : ''}`;
        },
    },
    watch: {
        value(newValue) {
            this.changed = !!JSON.parse(newValue);
        },
    },
    methods: {
        empty,
        inputHandler() {
            this.$emit('input', !!JSON.parse(this.changed));
        },
    },
};
</script>
