<template>
    <div class="input-group">
        <input
            v-bind="attributes"
            ref="input"
        >
        <input
            ref="hiddenInput"
            data-testid="input"
            type="hidden"
            :name="nameForHidden"
            :value="valueForHidden"
        >
        <button
            :disabled="disableButton"
            type="button"
            data-testid="button"
            class="btn btn-outline-secondary btn-icon admin_customers_billing_finance_items_tariff cursor-pointer"
            @click="clickButton"
        >
            <i
                v-if="clearButton && !emptyValue"
                class="icon-ic_fluent_dismiss_24_regular"
            />
            <i
                v-else
                class="icon-ic_fluent_calendar_ltr_24_regular"
            />
        </button>
    </div>
</template>

<script>
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputDateRange',
    mixins: [SplInputMixin],
    props: {
        options: Object,
        fields: Object, // Maybe need delete it
        dateRangePickerConfig: Object,
        value: String,
        clearButton: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            emptyValue: true,
            defaultClasses: ['input-date-range', 'form-control', 'v-filter-data-range'],
        };
    },
    computed: {
        attributes() {
            let options = { ...this.options };
            if (!isset(options, 'class')) {
                options.class = '';
            }
            options.class = this.addClass(options.class, this.defaultClasses);
            options.type = 'text';
            if (isset(options, 'name')) {
                // Name sets to hidden input instead of this
                delete options.name;
            }

            if (isset(options, 'value')) {
                delete options.value;
            }

            // Ignore visible input instead use value from hidden input
            options['data-ignore-filter'] = '1';

            return options;
        },
        nameForHidden() {
            if (isset(this.options, 'name')) {
                return this.options.name;
            }

            return '';
        },
        valueForHidden() {
            let value = this.getPreparedDateRangeValue(this.value);
            if (value === '') {
                return '';
            }

            return value;
        },
    },
    watch: {
        value(newValue) {
            let currentVal = $(this.$refs.hiddenInput).val();
            if (newValue != currentVal) {
                this.setValue(newValue);
            }
            this.emptyValue = empty(newValue);
        },
    },
    mounted() {
        let config = {};
        let self = this;
        $.extend(true, config, window.daterangepicker_default_config, this.dateRangePickerConfig);
        $(this.$refs.input).daterangepicker(config, (start, end) => {
            let value = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')}`;
            self.setValue(value);
            self.$emit('input', value);
        });

        initDaterangePicker();
        this.setValue(this.value);
        $(this.$refs.input).on('apply.daterangepicker', (element, picker) => {
            picker.callback(picker.startDate.clone(), picker.endDate.clone(), picker.chosenLabel);
        });
    },
    methods: {
        clickButton() {
            if (this.clearButton && !this.emptyValue) {
                $(this.$refs.input).val('').trigger('change');
                this.$emit('input', '');
                this.emptyValue = true;
            } else {
                $(this.$refs.input).trigger('click');
            }
        },
        setValue(value) {
            value = this.getPreparedDateRangeValue(value);
            let valueForVisibleInput = empty(value) ? '' : this.formatValue(value);
            $(this.$refs.input).val(valueForVisibleInput).trigger('change');

            let start = '';
            let
                end = '';

            if (!empty(value)) {
                let parts = value.split(' - ');
                let datepicker = $(this.$refs.input).data('daterangepicker');
                let pickerFormat = datepicker.locale.format;
                start = moment(parts[0], 'YYYY-MM-DD').format(pickerFormat);
                end = moment(parts[1], 'YYYY-MM-DD').format(pickerFormat);
                $(this.$refs.input).data('daterangepicker').setStartDate(start);
                $(this.$refs.input).data('daterangepicker').setEndDate(end);
            }
            this.emptyValue = empty(value);
        },
        formatValue(value) {
            let parts = value.split(' - ');
            let from = moment(parts[0], 'YYYY-MM-DD').format(date_format);
            let to = moment(parts[1], 'YYYY-MM-DD').format(date_format);

            return `${from} - ${to}`;
        },
        getPreparedDateRangeValue(dateRange) {
            let newDateRange = dateRange;
            if (dateRange === 'RANGE_THIS_MONTH') {
                // Default is range: first day of this month - last day of this month
                const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
                const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD');
                newDateRange = `${startOfMonth} - ${endOfMonth}`;
            } else if (dateRange === 'RANGE_CURRENT_DAY') {
                const today = moment().clone().format('YYYY-MM-DD');
                newDateRange = `${today} - ${today}`;
            }

            if (newDateRange !== dateRange) {
                this.$emit('input', newDateRange);
            }

            return newDateRange;
        },
    },
};
</script>
