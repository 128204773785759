<template>
    <div class="input-block x-input-number">
        <input
            v-bind="attributes"
            ref="input"
            type="text"
            :placeholder="placeholderNumber"
            :value="textNumber"
            autocomplete="off"
            tabindex="1"
            @change="changedByKeyboard"
            @focusout="emitChange"
            @keydown.down="changedByArrow($event, -step)"
            @keydown.up="changedByArrow($event, step)"
            @keydown.enter="changeByEnter"
        >
        <div
            v-if="stepArrows"
            ref="arrows"
            class="count-control"
        >
            <i
                class="icon-ic_fluent_chevron_up_24_regular increase"
                @click="changedByArrow($event, step, true)"
            />
            <i
                class="icon-ic_fluent_chevron_down_24_regular decrease"
                @click="changedByArrow($event, -step, true)"
            />
        </div>
    </div>
</template>

<script>
import SplInputMixin from '../input-mixin';

/**
 * @see See [Documentation](https://docs.dev.spl.co.ua/en/fecodewiki/base-components/XInputNumber)
 */
export default {
    name: 'XInputNumber',
    mixins: [SplInputMixin],
    props: {
        options: Object,
        value: {
            type: [Number, String],
            default: 0,
        },
        maximum: {
            type: [Number, String],
            default: null,
        },
        minimum: {
            type: [Number, String],
            default: 0,
        },
        numberType: {
            type: [String],
            default: 'integer',
        },
        initThousandSeparator: {
            type: [String],
            default: null,
        },
        allowEmpty: {
            type: [Boolean],
            default: false,
        },
        initDecimalSeparator: {
            type: [String],
            default: null,
        },
        stepChange: {
            type: [Number, String],
            default: null,
        },
        float: {
            type: [Number, String],
            default: null,
        },
        stripTrailingZeros: {
            type: [Boolean],
            default: false,
        },
        stepArrows: {
            type: [Boolean],
            default: true,
        },
        submitModal: {
            type: [Boolean],
            default: false,
        },
    },
    data() {
        return {
            isInit: true,
        };
    },

    computed: {
        textNumber(newValue) {
            if (this.isInit) {
                if (this.allowEmpty) {
                    if (!newValue.value) {
                        return '';
                    }
                }
                if (!newValue.value) {
                    return this.validationAfter(0);
                }
            }
            return new RealNumber(newValue.value, {
                float: this.floatDigits,
                decimalSeparator: this.decimalSeparator,
                thousandSeparator: this.thousandSeparator,
                stripTrailingZeros: this.stripTrailingZeros,
            })._stringFormat;
        },

        placeholderNumber() {
            if (this.value) {
                return new RealNumber(this.value, {
                    float: this.floatDigits,
                    decimalSeparator: this.decimalSeparator,
                    thousandSeparator: this.thousandSeparator,
                    stripTrailingZeros: this.stripTrailingZeros,
                })._stringFormat;
            }
            return 0;
        },

        attributes() {
            let attributes = { ...this.options };
            if (!isset(attributes, 'class')) {
                attributes.class = '';
            }
            attributes.class = this.addClass(attributes.class, ['form-control', 'input-sm']);
            return attributes;
        },

        floatDigits() {
            if (this.float) {
                return this.float;
            }

            switch (this.numberType) {
                case 'float':
                case 'decimal':
                    return parseFloat(window.spl_config.numberConfig?.decimal?.digits);
                default:
                    return 0;
            }
        },

        step() {
            if (this.stepChange && this.numberType !== 'integer') {
                return this.stepChange;
            }
            return 1;
        },

        decimalSeparator() {
            if (this.initDecimalSeparator == null) {
                return window.spl_config?.numberConfig?.decimal?.separator;
            }

            return this.initDecimalSeparator;
        },

        thousandSeparator() {
            if (this.initThousandSeparator == null) {
                return window.spl_config?.numberConfig?.thousand?.separator;
            }
            return this.initThousandSeparator;
        },
    },
    methods: {
        emitChange() {
            this.isInit = false;
            this.$refs.input.value = this.validationAfter(new RealNumber(this.value, {
                float: this.floatDigits,
                decimalSeparator: this.decimalSeparator,
                thousandSeparator: this.thousandSeparator,
                stripTrailingZeros: this.stripTrailingZeros,
            })._stringFormat);
        },

        changeByEnter(event) {
            if (this.submitModal) {
                this.changedByKeyboard(event);
            }
        },

        changedByKeyboard(event) {
            this.isInit = false;

            let value = this.validationAfter(new RealNumber(event.target.value, {
                float: this.floatDigits,
                decimalSeparator: this.decimalSeparator,
                thousandSeparator: this.thousandSeparator,
                stripTrailingZeros: this.stripTrailingZeros,
            })._numberFormat);
            this.$emit('input', parseFloat(parseFloat(value).toFixed(this.floatDigits)));
        },

        changedByArrow(event, step, byClick) {
            this.isInit = false;
            let targetValue;
            if (byClick) {
                targetValue = this.textNumber;
            } else {
                targetValue = event.target.value;
            }

            let value = this.validationAfter(new RealNumber(targetValue, {
                float: this.floatDigits,
                decimalSeparator: this.decimalSeparator,
                thousandSeparator: this.thousandSeparator,
                stripTrailingZeros: this.stripTrailingZeros,
            })._numberFormat + step);
            this.$emit('input', parseFloat(parseFloat(value).toFixed(this.floatDigits)));
        },

        validationAfter(value) {
            if (Number.isNaN(parseFloat(value))) {
                return 0;
            }
            if (this.maximum != null) {
                if (parseFloat(value) > parseFloat(this.maximum)) {
                    return this.maximum;
                }
            }

            if (this.minimum != null) {
                if (parseFloat(value) < parseFloat(this.minimum)) {
                    return this.minimum;
                }
            }
            return value;
        },
    },
};
</script>
